<template>
  <div>
    <h2>
      {{tradingName}}/{{flowName}}
    </h2>
    <div style="min-height: 388px">
      <tw-table-system :height="300" :schemas="schemas" :items="mappedItems" :pageSize="pageSize" :selection="selection" :rowKey="'documentArchiveId'" @selection-change="selectionChange" @select-file="download" />
    </div>
    <div style="display: flex;justify-content: flex-end;padding-top: 12px">
      <tw-button type="primary" :disabled="!selectFiles.length"  @click="register">OK</tw-button>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { processNames } from '@/dictionaries/map.js';
import TwButton from '@/components/atoms/TwButton';
import TwTableSystem from '@/components/organisms/TwTableSystem';
import schemas from '@/dictionaries/addDocumentsSchema.json';
import { getProcessNumber } from '@/utils/entity';

export default {
  name: 'TwAddDocuments',
  components: {
    TwButton,
    TwTableSystem,
  },
  props: {
    items: Array,
    tradingName: String,
    flowName: String
  },
  data() {
    return {
      schemas: schemas,
      pageSize: 20,
      selection: true,
      sections: [],
      selectFiles: [],
    };
  },
  computed: {
    mappedItems() {
      return _.map(this.items, item => {
        return {
          ...item,
          processName: _.get(processNames[item.processId.replace(/\d/g, '')], 'nameShort') + getProcessNumber(item.processId)
        };
      });
    }
  },
  methods: {
    selectionChange(multipleSelection) {
      this.selectFiles = multipleSelection;
    },
    register() {
      this.$emit('add-documents', this.selectFiles);
    },
    download(row) {
      this.$emit('download', row);
    }
  }
};
</script>

<style lang="scss">
  .el-dialog__wrapper[aria-label="Add Select File"] .el-dialog {
    .el-dialog__body {
      padding-top: 0;
    }

    h2 {
      margin: 0 0 24px;
      padding: 12px 0;
      border-bottom: 1px solid $color_gray_300;
      color: $color_gray_800;
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
    }
  }

  .tw_button__icon.icon_search {
    padding-left: 12px;
    padding-right: 20px;
  }
</style>
